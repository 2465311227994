import React from "react"
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"

interface ConfirmationModalProps {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  title?: string
  description?: string
  confirmButtonVariant?: string
  cancelButtonVariant?: string
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  title = "Are you sure?",
  description = "This action cannot be undone.",
  confirmButtonVariant = "danger",
  cancelButtonVariant = "outline-secondary",
}) => {
  const handleConfirm = () => {
    onConfirm()
    onClose()
  }

  return (
    <Modal show={isOpen} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>{description}</Modal.Body>

      <Modal.Footer>
        <Button variant={cancelButtonVariant} onClick={onClose}>
          Cancel
        </Button>
        <Button variant={confirmButtonVariant} onClick={handleConfirm}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ConfirmationModal
