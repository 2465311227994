import React from "react"
import { Link } from "react-router-dom"
import { Plus } from "lucide-react"

interface PageTitleProps {
  title: React.ReactNode
  /** If provided, will create a default "New" button with this path */
  newItemPath?: string
  /** Custom text for the new button (defaults to "New {title}") */
  newItemText?: string
  /** Additional class names for the container */
  className?: string
  /** Additional class names for the title */
  titleClassName?: string
  /** Additional class names for the right content container */
  rightClassName?: string
  chilren: []
}

const PageTitle: React.FC<PageTitleProps> = ({
  title,
  newItemPath,
  newItemText,
  children,
  className = "",
  titleClassName = "",
  rightClassName = "",
}) => {
  // Remove "s" at the end if title is a string and we're using it for the new button
  const singularTitle = typeof title === "string" ? title.replace(/s$/, "") : ""

  const defaultNewButton = newItemPath && (
    <Link to={newItemPath} className="btn btn-primary">
      <Plus className="me-2" />
      {newItemText || `New ${singularTitle}`}
    </Link>
  )

  return (
    <div className={`page-title-box d-flex flex-align-center ${className}`}>
      <h1 className={`page-title ${titleClassName}`}>{title}</h1>
      {(children || defaultNewButton) && (
        <div className={`page-title-right ms-auto ${rightClassName}`}>
          {children || defaultNewButton}
        </div>
      )}
    </div>
  )
}

export default PageTitle
