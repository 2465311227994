import React from "react"
import { Link, useLocation } from "react-router-dom"
import { startsWith } from "lodash"
import { House, Notebook, Building2 } from "lucide-react"

const Navigation = () => {
  const pathname = useLocation().pathname

  const renderNavItem = (name, link, icon) => {
    const isActive = link === pathname || (link != "/" && startsWith(pathname, link))
    return (
      <li className={`side-nav-item ${isActive && "menuitem-active"}`}>
        <Link to={link} className="side-nav-link">
          {icon}
          <span className="ms-2">{name}</span>
        </Link>
      </li>
    )
  }

  return (
    <div className="leftside-menu menuitem-active text-start">
      <Link to="/" className="logo">
        <span className="logo-lg">
          <img src="/images/logo.svg" />
        </span>
        <span className="logo-sm">
          <img src="/images/logo-small.svg" />
        </span>
      </Link>
      <p className="text-center text-white">Front Office (Beta)</p>
      <ul className="side-nav">
        {renderNavItem("Home", "/", <House />)}
        <li className="side-nav-title">Group Sales</li>
        {renderNavItem("Bookings", "/bookings", <Notebook />)}
        {renderNavItem("Areas", "/areas", <Building2 />)}
        {
          // renderNavItem("Clients", "/clients", "user")
        }
        {
          // <li className="side-nav-title">Corporate <sup>Coming Soon!</sup></li>
          // { renderNavItem("Partners", "/#partners", "pricetag-alt") }
          // { renderNavItem("Contracts", "/#contracts", "file") }
        }
        {
          // <li className="side-nav-title">Financials</li>
          // renderNavItem("Payments", "/payments", <CreditCard />)
        }
        {
          // <li className="side-nav-title">Insights</li>
          // {renderNavItem("Sales Report", "/insights/sales", <Receipt />)}
          // {renderNavItem("Booking Service Report", "/insights/bookings", <Pizza />)}
        }
      </ul>
      <div className="clearfix"></div>
    </div>
  )
}

export default Navigation
