import React, { useState, useRef, useEffect } from "react"
import { Link } from "react-router-dom"

interface DropdownItemBase {
  label: string
  icon?: React.ElementType
  className?: string
}

type DropdownItem = (
  | { onClick: () => void; href?: never; to?: never }
  | { href: string; target?: "_blank" | "_self" | "_parent" | "_top"; onClick?: never; to?: never }
  | { to: string; onClick?: never; href?: never }
) &
  DropdownItemBase

interface CatchDropdownProps {
  label: string
  items: DropdownItem[]
  className?: string
  disabled?: boolean
  icon?: React.ElementType
  iconOnly?: boolean
}

const CatchDropdown: React.FC<CatchDropdownProps> = ({
  label,
  items,
  className = "",
  disabled = false,
  icon: IconComponent,
  iconOnly = false,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false)
      }
    }
    document.addEventListener("click", handleClickOutside)
    return () => {
      document.removeEventListener("click", handleClickOutside)
    }
  }, [])

  const handleToggle = () => {
    if (!disabled) {
      setIsOpen(!isOpen)
    }
  }

  const handleActionClick = (onClick: () => void) => {
    onClick()
    setIsOpen(false)
  }

  const renderItem = (item: DropdownItem) => {
    const ItemIcon = item.icon
    const commonClasses = `dropdown-item ${item.className || ""}`
    const iconElement = ItemIcon && (
      <ItemIcon className="me-2" style={{ width: "1em", height: "1em" }} />
    )

    if ("to" in item && item.to) {
      return (
        <Link
          to={item.to}
          className={commonClasses}
          role="menuitem"
          onClick={() => setIsOpen(false)}
        >
          {iconElement}
          {item.label}
        </Link>
      )
    }

    if ("href" in item && item.href) {
      return (
        <a
          href={item.href}
          target={item.target}
          className={commonClasses}
          role="menuitem"
          onClick={() => setIsOpen(false)}
        >
          {iconElement}
          {item.label}
        </a>
      )
    }

    return (
      <button
        onClick={() => handleActionClick(item.onClick)}
        className={commonClasses}
        role="menuitem"
        type="button"
      >
        {iconElement}
        {item.label}
      </button>
    )
  }

  return (
    <div className="dropdown d-inline p-relative" ref={dropdownRef}>
      <button
        type="button"
        disabled={disabled}
        onClick={handleToggle}
        className={`btn btn-secondary ${iconOnly ? "btn-icon" : "dropdown-toggle"} ${className}`}
        aria-expanded={isOpen}
        title={iconOnly ? label : undefined}
      >
        {IconComponent && (
          <IconComponent
            className={iconOnly ? "" : "me-2"}
            style={{ width: "1em", height: "1em" }}
          />
        )}
        {!iconOnly && (
          <>
            {label}
            &nbsp;
          </>
        )}
      </button>
      <ul
        className={`dropdown-menu mt-1 ${isOpen ? "show" : ""}`}
        style={{ position: "absolute", right: 0 }}
      >
        {items.map((item) => (
          <li key={item.label}>{renderItem(item)}</li>
        ))}
      </ul>
    </div>
  )
}

export default CatchDropdown
