// Pagination.tsx
import React from "react"

// PaginationMetadata.types.ts
interface PaginationVars {
  count: number
  count_args: string[]
  ends: boolean
  items: string
  limit: number
  outset: number
  page: number
  page_param: string
  size: number
}

interface PaginationMetadata {
  count: number
  from: number
  in: number
  last: number
  limit: number
  next: number | null
  offset: number
  outset: number
  page: number
  prev: number | null
  to: number
  vars: PaginationVars
}

interface PaginationProps {
  metadata: PaginationMetadata
  onPageChange: (page: number) => void
  windowSize?: number // Total number of page buttons to show (excluding prev/next)
}

const Pagination: React.FC<PaginationProps> = ({
  metadata,
  onPageChange,
  windowSize = 5, // This will show: 1 ... X X [X] X X ... N
}) => {
  const totalPages = Math.ceil(metadata.count / metadata.limit)
  const currentPage = metadata.page

  const generatePageNumbers = () => {
    const numbers: (number | string)[] = []

    // If total pages is less than window size, show all pages
    if (totalPages <= windowSize) {
      for (let i = 1; i <= totalPages; i++) {
        numbers.push(i)
      }
      return numbers
    }

    // Always show first page
    numbers.push(1)

    const sidePages = Math.floor((windowSize - 3) / 2) // -3 for first, last, and ellipsis
    const leftBound = Math.max(2, currentPage - sidePages)
    const rightBound = Math.min(totalPages - 1, currentPage + sidePages)

    // Add left ellipsis if needed
    if (leftBound > 2) {
      numbers.push("...")
    }

    // Add pages around current page
    for (let i = leftBound; i <= rightBound; i++) {
      numbers.push(i)
    }

    // Add right ellipsis if needed
    if (rightBound < totalPages - 1) {
      numbers.push("...")
    }

    // Always show last page
    numbers.push(totalPages)

    // Adjust if we have too few numbers
    while (numbers.length < windowSize) {
      const lastNum = numbers[numbers.length - 2]
      const firstNum = numbers[1]

      if (typeof lastNum === "number" && lastNum < totalPages - 1) {
        numbers.splice(numbers.length - 1, 0, lastNum + 1)
      } else if (typeof firstNum === "number" && firstNum > 2) {
        numbers.splice(1, 0, firstNum - 1)
      } else {
        break
      }
    }

    return numbers
  }

  if (totalPages <= 1) return null

  const renderPageNumbers = () => {
    return generatePageNumbers().map((pageNum, index) => {
      if (pageNum === "...") {
        return (
          <li key={`ellipsis-${index}`} className="page-item disabled">
            <span className="page-link">...</span>
          </li>
        )
      }

      const isCurrentPage = pageNum === currentPage
      return (
        <li key={`page-${pageNum}`} className={`page-item ${isCurrentPage ? "active" : ""}`}>
          <button
            className="page-link"
            onClick={() => typeof pageNum === "number" && onPageChange(pageNum)}
            aria-current={isCurrentPage ? "page" : undefined}
          >
            {pageNum}
          </button>
        </li>
      )
    })
  }

  return (
    <div className="d-flex align-items-center gap-3">
      <div className="text-muted ms-auto">
        Showing {metadata.from}-{metadata.to} of {metadata.count}
      </div>
      <nav aria-label="Page navigation">
        <ul className="pagination mb-0">
          <li key="prev" className={`page-item ${!metadata.prev ? "disabled" : ""}`}>
            <button
              className="page-link"
              onClick={() => metadata.prev && onPageChange(currentPage - 1)}
              disabled={!metadata.prev}
            >
              <span aria-hidden="true">&laquo;</span>
              <span className="visually-hidden">Previous</span>
            </button>
          </li>

          {renderPageNumbers()}

          <li key="next" className={`page-item ${!metadata.next ? "disabled" : ""}`}>
            <button
              className="page-link"
              onClick={() => metadata.next && onPageChange(currentPage + 1)}
              disabled={!metadata.next}
            >
              <span aria-hidden="true">&raquo;</span>
              <span className="visually-hidden">Next</span>
            </button>
          </li>
        </ul>
      </nav>
    </div>
  )
}

export default Pagination
