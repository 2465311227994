import React, { useState } from "react"
import { Navigate } from "react-router-dom"
import getAppConfig from "~/utils/getAppConfig"

import { apiBookings } from "~/routes"

import PageContainer from "~/library/PageContainer"
import BookingForm from "~/components/BookingForm"

const NewBooking = () => {
  const { venueSlug } = getAppConfig()
  const [createdBookingId, setCreatedBookingId] = useState(null)

  const handleSubmit = (formValues) => {
    const data = {
      venue_slug: venueSlug,
      booking: formValues,
    }

    const req = apiBookings.create({ data })
    req.then(({ booking }) => {
      setCreatedBookingId(booking.id)
    })
  }

  if (createdBookingId) {
    return <Navigate to={`/bookings/${createdBookingId}`} />
  }

  return (
    <PageContainer>
      <div className="row h-100">
        <div className="col-6 offset-3 align-center mt-4">
          <h3 className="mb-3">New Booking</h3>
          <BookingForm onSubmit={handleSubmit} />
        </div>
      </div>
    </PageContainer>
  )
}

export default NewBooking
