import React, { useEffect, createContext, useContext, useRef } from "react"
import { Formik, Form, Field } from "formik"

const FormContext = createContext()

export const useFormValues = () => {
  const context = useContext(FormContext)
  if (!context) {
    throw new Error("useFormValues must be used within a FormikWrapper")
  }
  return context
}

const FormikWrapper = ({
  initialValues = {},
  validationSchema,
  onSubmit,
  onValuesChange,
  valueResetRules = [], // Array of { watchField, resetField, resetValue }
  children,
}) => {
  const isFormControl = (element) => {
    const formControlElements = ["input", "select", "textarea"]
    const elementType = element.type?.toLowerCase?.() || ""
    const isFormikField = element.type === Field
    const isCustomFormComponent =
      element.type.name === "FormInput" || element.type.name === "FormikTextarea"
    const hasNameProp = !!element.props?.name

    return (
      (formControlElements.includes(elementType) || isFormikField || isCustomFormComponent) &&
      hasNameProp
    )
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount={false}
      validateOnChange={true}
      onSubmit={async (values, helpers) => {
        try {
          await onSubmit(values, helpers)
        } catch (error) {
          helpers.setErrors({ submit: error.message })
        } finally {
          helpers.setSubmitting(false)
        }
      }}
    >
      {(formikProps) => {
        const { values, errors, touched, isSubmitting, setFieldValue } = formikProps

        return (
          <FormContext.Provider value={formikProps}>
            <Form className="mb-3">
              {errors.submit && (
                <div className="alert alert-danger" role="alert">
                  {errors.submit}
                </div>
              )}

              {React.Children.map(children, (child) => {
                if (!React.isValidElement(child)) return child

                const name = child.props.name
                if (!name) return child

                const error = touched[name] && errors[name]

                const shouldAddFormControl = isFormControl(child)
                const className = shouldAddFormControl
                  ? `form-control ${error ? "is-invalid" : touched[name] ? "is-valid" : ""} ${
                      child.props.className || ""
                    }`
                  : child.props.className

                return React.cloneElement(child, {
                  error,
                  touched: touched[name],
                  disabled: isSubmitting,
                  className,
                })
              })}

              <FormValuesWatcher
                values={values}
                onChange={onValuesChange}
                resetRules={valueResetRules}
                setFieldValue={setFieldValue}
              />
            </Form>
          </FormContext.Provider>
        )
      }}
    </Formik>
  )
}

const FormValuesWatcher = ({ values, onChange, resetRules, setFieldValue }) => {
  const previousValues = useRef(values)

  useEffect(() => {
    // Handle general onChange callback
    if (onChange) {
      onChange(values)
    }

    // Check each reset rule
    resetRules.forEach(({ watchField, resetField, resetValue }) => {
      // Only proceed if the watched value has actually changed
      if (values[watchField] !== previousValues.current[watchField]) {
        const newValue =
          typeof resetValue === "function" ? resetValue(values[watchField], values) : resetValue

        setFieldValue(resetField, newValue)
      }
    })

    // Update previous values
    previousValues.current = values
  }, [values, onChange, resetRules, setFieldValue])

  return null
}

export default FormikWrapper
