// JsFromRoutes CacheKey 4473ff418751367fa49b7a770d86d438
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from "@js-from-routes/client"

export default {
  index: /* #__PURE__ */ definePathHelper("get", "/api/:venue_slug/bookings"),
  create: /* #__PURE__ */ definePathHelper("post", "/api/:venue_slug/bookings"),
  show: /* #__PURE__ */ definePathHelper("get", "/api/:venue_slug/bookings/:id"),
  update: /* #__PURE__ */ definePathHelper("patch", "/api/:venue_slug/bookings/:id"),
}
