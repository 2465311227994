import React, { useState } from "react"
import { Link, Navigate } from "react-router-dom"
import getAppConfig from "~/utils/getAppConfig"

import PageContainer from "~/library/PageContainer"
import CatchCard from "~/library/CatchCard"

import { apiAreas } from "~/routes"

import * as Yup from "yup"
import FormikWrapper from "~/library/FormikWrapper"
import FormikInput from "~/library/FormikInput"

const areaSchema = Yup.object({
  name: Yup.string().required("A name is required"),
})

const AreaForm = () => {
  const { venueSlug } = getAppConfig()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [newAreaId, setNewAreaId] = useState(null)

  const submitArea = (values) => {
    setIsSubmitting(true)
    const req = apiAreas.create({
      data: {
        venue_slug: venueSlug,
        area: values,
      },
    })
    req.then(({ area }) => {
      setNewAreaId(area.id)
    })
    req.finally(() => {
      setIsSubmitting(false)
    })
  }

  if (newAreaId) return <Navigate to={`/areas/${newAreaId}`} />

  return (
    <PageContainer>
      <div className="row pt-4">
        <CatchCard className="col-6 offset-3" title="New Bookable Area">
          <FormikWrapper
            onSubmit={submitArea}
            initialValues={{
              name: "",
              capacity: "",
              description: "",
            }}
            validationSchema={areaSchema}
          >
            <FormikInput name="name" label="Area Name" placeholder="Suite 7" />
            <FormikInput
              name="capacity"
              label="Capacity (in People)"
              placeholder="8"
              type="number"
            />
            <FormikInput name="description" label="Area Description" />
            <div className="pt-2 d-flex">
              <Link to="/areas" className="ms-auto btn btn-outline-secondary me-2">
                Cancel
              </Link>
              <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                Submit
              </button>
            </div>
          </FormikWrapper>
        </CatchCard>
      </div>
    </PageContainer>
  )
}

export default AreaForm
