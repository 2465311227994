// JsFromRoutes CacheKey 62994b27270ad303892567eda0a316b9
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from "@js-from-routes/client"

export default {
  availability: /* #__PURE__ */ definePathHelper("get", "/api/:venue_slug/areas/availability"),
  index: /* #__PURE__ */ definePathHelper("get", "/api/:venue_slug/areas"),
  create: /* #__PURE__ */ definePathHelper("post", "/api/:venue_slug/areas"),
  show: /* #__PURE__ */ definePathHelper("get", "/api/:venue_slug/areas/:id"),
  update: /* #__PURE__ */ definePathHelper("patch", "/api/:venue_slug/areas/:id"),
}
