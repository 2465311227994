import React, { useState } from "react"
import { Navigate, useParams } from "react-router-dom"
import { useToast } from "~/utils/Toast"

import useFetchResource from "~/utils/useFetchResource"
import getAppConfig from "~/utils/getAppConfig"
import { apiBookings } from "~/routes"

import PageContainer from "~/library/PageContainer"

import BookingForm from "~/components/BookingForm"
import LoadingWrapper from "~/library/LoadingWrapper"

const EditBooking = () => {
  const [isBookingUpdated, setIsBookingUpdated] = useState(null)

  const { id } = useParams()
  const { venueSlug } = getAppConfig()
  const toast = useToast()

  const endpoint = apiBookings.show.path({ venueSlug, id })
  const { state: booking } = useFetchResource(endpoint, "booking", {})

  const handleSubmit = (formValues) => {
    const data = {
      venue_slug: venueSlug,
      id: booking.id,
      booking: formValues,
    }

    const req = apiBookings.update({ data })
    req.then(() => {
      setIsBookingUpdated(true)
    })
    req.catch((res) => {
      toast(`Error updating Booking. ${res.body.errors.join(", ")}`, "danger")
    })
  }

  if (isBookingUpdated) {
    return <Navigate to={`/bookings/${booking?.id}`} />
  }

  return (
    <PageContainer>
      <div className="row h-100">
        <div className="col-6 offset-3 align-center mt-4">
          <h3 className="mb-3">Edit Booking</h3>
          <LoadingWrapper isLoading={!booking?.id}>
            <BookingForm onSubmit={handleSubmit} initialValues={booking} />
          </LoadingWrapper>
        </div>
      </div>
    </PageContainer>
  )
}

export default EditBooking
