import { Field, FieldProps } from "formik"
import { ComponentPropsWithoutRef, forwardRef, ReactNode } from "react"

type Option = {
  label: string
  value: string
  disabled: boolean
  description?: string
  icon?: string
}

type RadioOptionProps = ComponentPropsWithoutRef<"input"> & {
  label: string
  checked: boolean
  description?: string
  icon?: string
  variant?: "default" | "card"
  id: string
}

const RadioOption = forwardRef<HTMLInputElement, RadioOptionProps>(
  (
    { label, checked, className, disabled, description, icon, variant = "default", id, ...props },
    ref
  ) => {
    if (variant === "card") {
      return (
        <label
          htmlFor={id}
          className={`
            card border-2 mb-2
            ${checked ? "border-primary bg-light" : "border-gray"}
            ${disabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer"}
            transition-all
          `}
        >
          <div className="card-body p-3 d-flex align-items-center">
            <div>
              <h5 className="card-title mb-1">
                {icon && <i className={`bi ${icon} me-2`}></i>}
                {label}
              </h5>
              {description && (
                <p className="card-text text-muted mb-0" style={{ fontSize: 13 }}>
                  {description}
                </p>
              )}
            </div>
            <input
              {...props}
              ref={ref}
              id={id}
              type="radio"
              disabled={disabled}
              checked={checked}
              className="form-check-input ms-auto mt-0"
            />
          </div>
        </label>
      )
    }

    return (
      <div className="form-check">
        <input
          {...props}
          ref={ref}
          id={id}
          type="radio"
          disabled={disabled}
          checked={checked}
          className={`form-check-input ${className || ""}`}
        />
        <label htmlFor={id} className={`form-check-label ${disabled ? "text-muted" : ""}`}>
          {icon && <i className={`bi ${icon} me-2`}></i>}
          {label}
          {description && <small className="d-block text-muted">{description}</small>}
        </label>
      </div>
    )
  }
)

RadioOption.displayName = "RadioOption"

type FormikRadioGroupProps = {
  options: Option[]
  name: string
  className?: string
  inline?: boolean
  variant?: "default" | "card"
  emptyState?: ReactNode
  renderOption?: (option: Option & { checked: boolean }) => ReactNode
}

const FormikRadioGroup = ({
  options,
  name,
  className,
  inline,
  variant = "default",
  emptyState,
  renderOption,
}: FormikRadioGroupProps) => {
  if (options.length === 0 && emptyState) {
    return <div className="text-center p-4 bg-light rounded">{emptyState}</div>
  }

  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => (
        <div className={`${inline ? "btn-group" : ""} ${className || ""}`}>
          {options.map((option) => {
            if (renderOption) {
              return (
                <div
                  key={option.value}
                  onClick={() => !option.disabled && form.setFieldValue(name, option.value)}
                  className={`
                    ${option.disabled ? "opacity-50" : "cursor-pointer"}
                  `}
                >
                  <input
                    type="radio"
                    {...field}
                    value={option.value}
                    checked={field.value === option.value}
                    className="d-none"
                  />
                  {renderOption({ ...option, checked: field.value === option.value })}
                </div>
              )
            }

            if (inline) {
              return (
                <div key={option.value}>
                  <input
                    {...field}
                    type="radio"
                    id={`${name}-${option.value}`}
                    value={option.value}
                    disabled={option.disabled}
                    checked={field.value === option.value}
                    className="btn-check"
                  />
                  <label
                    className={`btn btn-outline-primary ${option.disabled ? "disabled" : ""}`}
                    htmlFor={`${name}-${option.value}`}
                  >
                    {option.icon && <i className={`bi ${option.icon} me-2`}></i>}
                    {option.label}
                  </label>
                </div>
              )
            }

            return (
              <RadioOption
                key={option.value}
                {...field}
                id={`${name}-${option.value}`}
                label={option.label}
                value={option.value}
                disabled={option.disabled}
                checked={field.value === option.value}
                description={option.description}
                icon={option.icon}
                variant={variant}
                onChange={() => form.setFieldValue(name, option.value)}
              />
            )
          })}
        </div>
      )}
    </Field>
  )
}

export default FormikRadioGroup
