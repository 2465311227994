import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"

import getAppConfig from "~/utils/getAppConfig"
import { apiAreas } from "~/routes"

import CatchCard from "~/library/CatchCard"

import * as Yup from "yup"
import FormikWrapper from "~/library/FormikWrapper"
import FormikInput from "~/library/FormikInput"
import FormikTextarea from "~/library/FormikTextarea"
import FormikRadioGroup from "~/library/FormikRadioGroup"
import CurrencyInput from "~/library/CurrencyInput"
import DateSelector from "~/library/DateSelector"
import LoadingWrapper from "~/library/LoadingWrapper"

const bookingSchema = Yup.object({
  clientName: Yup.string().required("Client Name is Required"),
  clientEmail: Yup.string().email().required("Client Email is Required"),
  clientPhone: Yup.string().required("Client Phone is Required"),
  areaId: Yup.string().required("An area selection is required"),
  date: Yup.string().required("A booking date is required"),
  name: Yup.string().required("A booking name is required"),
  details: Yup.string(),
})

const BookingForm = ({ onSubmit, initialValues }) => {
  const { venueSlug } = getAppConfig()
  const [selectedDate, setSelectedDate] = useState(null)
  const [availability, setAvailability] = useState([])
  const [isBookedAreasLoading, setIsBookedAreasLoading] = useState(false)

  useEffect(() => {
    if (initialValues?.id) {
      setSelectedDate(initialValues.date)
    }
  }, [initialValues])

  useEffect(() => {
    if (selectedDate) {
      setIsBookedAreasLoading(true)
      const req = apiAreas.availability({
        venue_slug: venueSlug,
        query: { date: selectedDate },
      })
      req.then(({ areas }) => {
        setAvailability(areas)
      })
      req.finally(() => setIsBookedAreasLoading(false))
    }
  }, [venueSlug, selectedDate])

  const defaultValues = {
    name: "",
    clientName: "",
    clientEmail: "",
    clientPhone: "",
    areaId: "",
    anticipatedRevenueCents: 0,
    date: null,
  }

  return (
    <FormikWrapper
      onSubmit={onSubmit}
      validationSchema={bookingSchema}
      onValuesChange={({ date }) => setSelectedDate(date)}
      valueResetRules={[
        {
          watchField: "date",
          resetField: "areaId",
          resetValue: "",
        },
      ]}
      initialValues={initialValues?.id ? initialValues : defaultValues}
    >
      <h4>Booking Details</h4>
      <CatchCard>
        <FormikInput
          name="name"
          label="Booking Name"
          placeholder="Woodworking Enthusiests Outing"
          required
        />

        <CurrencyInput
          name="anticipatedRevenueCents"
          label="Anticipated Revenue"
          placeholder="8,6753.09"
          currency="USD"
        />
        <FormikTextarea
          name="details"
          label="Additional Details"
          placeholder="Client likes scotch, hates computers"
        />
      </CatchCard>
      <h4>Client Details</h4>
      <CatchCard>
        <FormikInput name="clientName" label="Client Name" placeholder="Ron Swanson" required />
        <FormikInput
          name="clientEmail"
          label="Client Email"
          placeholder="ron@verygoodbuilding.com"
          required
        />
        <FormikInput name="clientPhone" label="Client Phone" placeholder="555 123-1234" required />
      </CatchCard>
      <div>
        <h4>Select a Date</h4>
        <DateSelector
          name="date"
          isFormik={true}
          futureOnly={true}
          disabled={isBookedAreasLoading}
        />
      </div>
      <h4>Area</h4>
      <CatchCard>
        <LoadingWrapper isLoading={isBookedAreasLoading}>
          <div className="row"></div>
          <FormikRadioGroup
            name="areaId"
            variant="card"
            options={availability.map((a) => {
              const isBooked = a.isBooked && a.id != initialValues?.areaId
              return {
                value: a.id,
                label: `${a.name}${isBooked ? " (Booked)" : ""}`,
                disabled: isBooked,
                description: `Capacity: ${a.capacity}`,
              }
            })}
            emptyState={
              <div className="d-flex align-items-center justify-content-center">
                Date must be selected
              </div>
            }
          />
        </LoadingWrapper>
      </CatchCard>
      <div className="d-flex">
        <div className="ms-auto">
          <Link className="btn btn-outline-secondary me-2" to="/bookings">
            Cancel
          </Link>
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </div>
      </div>
    </FormikWrapper>
  )
}

export default BookingForm
