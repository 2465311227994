import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import moment from "moment"

import PageTitle from "~/library/PageTitle"
import LoadingWrapper from "~/library/LoadingWrapper"
import CatchCard from "~/library/CatchCard"
import CatchTable from "~/library/CatchTable"
import Pagination from "~/library/Pagination"

import getAppConfig from "~/utils/getAppConfig"
import useFetchResource from "~/utils/useFetchResource"
import { apiBookings } from "~/routes"

const BookingList = () => {
  const { venueSlug } = getAppConfig()
  const [page, setPage] = useState(1)

  const {
    state: bookings,
    refetch,
    metadata,
    isLoading,
  } = useFetchResource(apiBookings.index.path({ venueSlug }), "bookings", {})

  useEffect(() => {
    refetch({ page })
  }, [page])

  const formatCurrency = (currency) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(currency / 100)
  }

  return (
    <LoadingWrapper isLoading={isLoading}>
      <PageTitle title="Bookings" newItemPath="/bookings/new" />

      <div className="row">
        <div className="col-12">
          <CatchCard>
            <CatchTable
              data={bookings}
              columns={[
                {
                  key: "name",
                  header: "Booking",
                  formatter: (name, data) => <Link to={`/bookings/${data.id}`}>{name}</Link>,
                },
                {
                  key: "date",
                  header: "Date",
                  formatter: (date) => moment(date).format("MMM Do YYYY"),
                },
                {
                  key: "area",
                  header: "Area",
                  formatter: (area) => <Link to={`/areas/${area.id}`}>{area.name}</Link>,
                },
                {
                  key: "clientName",
                  header: "Client",
                },
                // {
                //   key: "payments",
                //   header: "Payments",
                // },
                {
                  key: "anticipatedRevenueCents",
                  header: "Anticipated Revenue",
                  formatter: (antRev) => formatCurrency(antRev),
                },
              ]}
            />
            <Pagination metadata={metadata} onPageChange={setPage} />
          </CatchCard>
        </div>
      </div>
    </LoadingWrapper>
  )
}

export default BookingList
